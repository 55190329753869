/* eslint-disable vue/no-v-html */
<template>
  <div class="overflow-hidden" v-if="items">
    <LayoutSwipeList
      id="bubbles"
      :container="false"
      :small="true"
      :fade="false"
      class="-mx-4 md:mx-0 pl-4 md:pl-0"
    >
      <SwiperSlide v-for="(item, index) in items" :key="index">
        <NuxtLink :id="'bubbles' + index" :to="item.Link">
          <NuxtImg
            v-if="item.Image"
            :src="item.Image"
            width="96"
            height="96"
            :alt="item.Title ? item.Title : ''"
            class="rounded-full h-18 w-18 md:h-24 md:w-24 z-10 object-cover object-center"
          />
          <div
            v-if="item.Title"
            class="list-label text-center mt-1 truncate w-18 md:w-24"
            v-html="item.Title"
          />
        </NuxtLink>
      </SwiperSlide>
    </LayoutSwipeList>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'

const props = defineProps({
  count: {
    type: Number,
    default: 5,
  },
  type: {
    type: String,
    default: 'default',
  },
})
const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()
const { data: items } = await useAsyncData(async () => {
  return await nuxtApp.$api.content.bubbles(indexStore.currentPaperIdentifier)
})
</script>

<style>
.dot {
  transform: translate(-0.4rem, 0.4rem);
  height: 10px;
  width: 10px;
  @screen md {
    transform: translate(-0.6rem, 0.6rem);
    height: 10px;
    width: 10px;
  }
  border-width: 2px;
  @apply bg-red rounded-full absolute right-0 top-0 border-white;
}
</style>
